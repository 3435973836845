<template>
    <div class="z-10 absolute top-0 left-0 w-full h-screen bg-gray-300 bg-opacity-90">
        <div class="z-20 absolute top-5 left-10 font-semibold cursor-pointer" @click="goBack">Go Back</div>
        <!-- <div data-tf-widget="q6yvfne1caw"></div> -->
        <iframe class="w-full h-screen opacity-70" src="https://form.typeform.com/to/ZlCd9yya" />
    </div>
</template>

<script>
export default {
    methods: {
        goBack() {
            this.$router.push({ path: '/' });
        }
    },
    created() {
        window.onscroll = () => {};
    }

}
</script>

<style scoped>
body, html {
    overflow: hidden
}
</style>